<template>
  <div>
    <!-- page header -->
    <h1 class="page-header">Orders <small>all listings.</small></h1>

    <!-- search -->
    <display-results
      ref="orderSearch"
      v-slot:default="{ items }"
      :index-name="ordersIndex"
      :facetList="[
        { text: 'Voucher Code', value: 'voucher_code' },
        { text: 'Order ID', value: 'id' },
        { text: 'Giftcard ID', value: 'giftcard_id' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Company Name', value: 'company_name' },
        { text: 'Company Organization Number', value: 'company_organization_number' },
        { text: 'Email', value: 'email' },
        { text: 'Address', value: 'address' },
        { text: 'Phone', value: 'phone' },
        { text: 'Product Name', value: 'product_name' },
        { text: 'Voucher Code With Transaction History', value: 'voucher_code_with_history' },
      ]"
    >
      <b-table
        ref="orderstable"
        :fields="fields"
        :items="mapItems(items)"
        show-empty
        stacked="sm"
        bordered
        hover
        @row-clicked="row => toggleDetails(row)"
      >
        <template v-slot:cell(id)="row">
          {{ row.item.id }}
          <b-button variant="primary" size="sm" class="ml-2" title="Copy ID" @click="copyText(row.item.id)">
            <i class="fa fa-copy" />
          </b-button>
        </template>
        <template v-slot:cell(name)="row">
          {{ `${row.item.first_name} ${row.item.last_name}` }}
        </template>

        <template v-slot:cell(delivery_method)="row">
          {{ `${row.item.delivery_method}` }}
          <b-button
            v-if="row.item.delivery_method === 'post' && row.item.paid"
            :variant="row.item.delivered_at === null ? 'primary' : 'info'"
            size="sm"
          >
            <span v-if="row.item.delivered_at === null" @click="markedPacked(row.item)">
              Mark as Packed
            </span>
            <span v-else> Packed </span>
          </b-button>
          <b-button target="_blank" size="sm" class="ml-2" :href="printLocation([row.item])">Print Voucher</b-button>
        </template>

        <template v-slot:cell(paid)="row">
          <b-badge :variant="row.item.paid ? 'primary' : 'warning'">
            {{ row.item.paid ? paidLabel(row.item) : 'Not Paid' }}
          </b-badge>
        </template>

        <template v-slot:cell(full_sum)="row">
          {{ formatMoney(row.item.full_sum) }}
        </template>

        <template v-slot:row-details="{ item }">
          <b-overlay :show="loading === item.id">
            <order-details :order="item" @loaded="loading = 0" @triggerUpdate="triggerSearch" />
          </b-overlay>
        </template>
      </b-table>
    </display-results>
  </div>
</template>

<script>
import OrderDetails from './OrderDetails.vue'
import DisplayResults from '../../components/DisplayResults.vue'
import {formatDate, formatDateOrdered, formatMoney} from '../page-helpers'
import { orders } from '@/api'

export default {
  name: 'OrdersPage',
  components: { DisplayResults, OrderDetails },
  data() {
    return {
      loading: 0,
      displayedDetailsForOrderIds: [],
      ordersIndex: process.env.VUE_APP_ALGOLIA_ORDERS_INDEX,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', sortable: false },
        { key: 'paid', sortable: false },
        { key: 'full_sum', sortable: false },
        { key: 'delivery_method', sortable: false },
        { key: 'created_at_formatted', label: 'Created At', sortable: false },
      ]
    },
  },
  methods: {
    formatMoney,
    copyText(text) {
      navigator.clipboard.writeText(text).then(() =>
        this.$bvToast.toast(`Order ID has been copied to clipboard.`, {
          title: 'ID copied',
          variant: 'primary',
        })
      )
    },
    mapItems(items) {
      return items.map(item => ({ ...item, _showDetails: this.displayedDetailsForOrderIds.includes(item.id) }))
    },
    triggerSearch() {
      this.$refs.orderSearch.triggerSearch()
    },
    async markedPacked(item) {
      await orders.markAsPacked([item.id])
      item.delivered_at = (new Date()).toISOString()
      this.$refs.orderSearch.triggerSearch()
      this.$bvToast.toast(`Order has been marked as packed.`, {
        title: 'Order marked as packed',
        variant: 'primary',
      })
    },
    printLocation(items) {
      const url = process.env.VUE_APP_API_URL
      const ids = items.map(item => item.id).join(',')
      return `${url}/orders/print?orders=${ids}`
    },
    toggleDetails(order) {
      const orderIdIndex = this.displayedDetailsForOrderIds.indexOf(order.id)
      this.loading = order.id

      if (orderIdIndex > -1) {
        this.displayedDetailsForOrderIds.splice(orderIdIndex, 1)
      } else {
        this.displayedDetailsForOrderIds.push(order.id)
      }

      order._showDetails = !order._showDetails
    },
    paidLabel(order) {
      const tracker = { exchanged: 0, redeemed: 0, refunded: 0, expired: 0 }
      const count = order.order_details.length - order.order_details.filter(i => i.type === 'order_addons').length

      for (let i = 0; i < order.order_details.length; i++) {
        const { giftcard } = order.order_details[i]

        if (giftcard === null) {
          continue
        }

        // expired
        if (Date.now() - Date.parse(giftcard.valid_to) > 0 && !giftcard.redeemed_at) {
          tracker.expired++
        }

        // redeemed
        if (giftcard.redeemed_at) {
          tracker.redeemed++
        }

        if (giftcard.refunded_at) {
          tracker.refunded++
        }

        if (giftcard.exchanged_at) {
          tracker.exchanged++
        }
      }
      const labels = []
      const keysCache = Object.keys(tracker)
      const noLabel = keysCache.every(key => tracker[key] === 0)
      const shouldCompound = keysCache.some(key => tracker[key] === count)

      if (!noLabel) {
        if (shouldCompound) {
          const compoundLabels = keysCache.filter(key => tracker[key] === count)
          labels.push(`(${compoundLabels.join(') (')})`)
        } else {
          keysCache.forEach(key => {
            if (tracker[key] > 0) {
              labels.push(
                `(${tracker[key]}/${count} ${key})`
              )
            }
          })
        }
      }

      return 'Paid' + (noLabel ? '' : ` ${labels.join(' ')}`)
    },
  },
}
</script>
