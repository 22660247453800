<template>
  <div class="box box-default">
    <div class="box-body box-comments">
      <div
        v-for="comment in comments"
        :key="comment.id"
        class="d-flex w-100"
        style="
    padding:  10px;
    border-bottom: 1px solid #d1d9e7;
"
      >
        <c-img style="width: 56px; margin-right: 18px;" class="img-circle img-sm" src="/img/user/default.png" alt="User Image" />
        <div class="comment-text d-flex flex-column w-100">
          <span class="username"
            ><strong>{{
              comment.user !== null ? `${comment.user.first_name} ${comment.user.last_name}` : 'Kundeservice'
            }}</strong>
            <small class="text-muted float-right"
              ><i class="fa fa-clock-o"></i> {{ formatDates(comment.created_at) }}</small
            ></span
          >
          {{ comment.comment }}
        </div>
        <hr />
      </div>
    </div>
    <div class="box-footer d-flex" style="padding: 10px;">
      <c-img
        style="width: 56px; margin-right: 18px;"
        class="img-responsive img-circle img-sm"
        src="/img/user/default.png"
        alt="User Image"
      />
      <div class="img-push w-100">
        <b-overlay :show="addingComment">
          <input
            type="text"
            v-model="comment"
            class="form-control input-sm"
            placeholder="Press enter to post comment"
            @keyup.enter.prevent="debouncedAddComment"
          />
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { orders } from '@/api'
import { formatDate } from '../page-helpers'
import debounce from 'lodash/debounce'

export default {
  props: ['data', 'order_id'],
  data() {
    return {
      comment: '',
      comments: this.data,
      addingComment: false,
    }
  },
  watch: {
    data(newVal) {
        this.comments = newVal;
    },
  },
  methods: {
    formatDates(date) {
      return formatDate(date)
    },
    async addComment() {
      this.addingComment = true
      const res = await orders.addComment(this.order_id, this.comment)
      this.$emit('updated')
      this.comment = ''
      this.comments.push(res.data)
      this.addingComment = false
    },
    debouncedAddComment: debounce(function () {
      this.addComment()
    }, 200),
  },
}
</script>

<style></style>
