<template>
    <b-modal title="Redeem Giftcard" :id="`redeem-giftcards`" hide-footer>
        <b-overlay :show="isLoading">
            <form @submit.prevent="redeem">
                <b-row align-v="end">
                    <b-col>
                        <b-form-group label="Experienced at">
                            <b-form-datepicker locale="nb-NO" :start-weekday="1" v-model="experienced_at" :min="new Date()" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-button variant="primary" style="margin-bottom: 1rem" type="submit">
                            Redeem
                        </b-button>
                    </b-col>
                </b-row>
            </form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { giftcard } from '@/api'
import { formatDate, formatMoney } from '../../page-helpers'

export default {
    name: 'RedeemGiftcardModal',

    props: {
        orderDetails: Array,
    },

    data() {
        return {
            isLoading: false,
            experienced_at: null,
        }
    },

    methods: {
        formatDate,
        formatMoney,

        async redeemGiftcard(id) {
            this.isLoading = true
            await giftcard.redeem(id, this.experienced_at)
        },

        redeem() {
            this.orderDetails.forEach(async od => {
                try {
                    await this.redeemGiftcard(od.giftcard.id)
                    this.$emit('triggerUpdate')
                    this.$emit('triggerLoadOrderDetails')
                    this.$bvToast.toast(`Giftcard with code ${od.giftcard.code} was succesfully redeemed.`, {
                        title: 'Giftcard Redeemed',
                        variant: 'primary',
                    })
                } catch (error) {
                    this.$bvToast.toast(`Giftcard with code ${od.giftcard.code} could not be redeemed`, {
                        title: 'Invalid Giftcard Redeem',
                        variant: 'danger',
                    })
                }
            })
            this.isLoading = false
            this.$bvModal.hide('redeem-giftcards')
            this.experienced_at = null
        },
    },
}
</script>
