<template>
  <div>
    <label :for="`datepicker-${giftcard.id}`">Choose a date</label>
    <b-form-datepicker
      locale="nb-NO"
      :start-weekday="1"
      v-model="value"
      class="mb-2"
      :min="giftcard.valid_to"
      :initial-date="giftcard.valid_to"
      :id="`datepicker-${giftcard.id}`"
    />
    <hr />
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button class="mr-2" @click="$emit('cancel')">Cancel</b-button>
        <b-button variant="primary" @click="extendDate">
          Extend Date
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { giftcard } from '@/api'

export default {
  name: 'OrderDetailDateForm',
  props: { giftcard: { type: Object } },
  data() {
    return { value: this.giftcard.valid_to }
  },
  methods: {
    extendDate() {
      giftcard.update(this.giftcard.id, {
        valid_to: this.value,
        order_detail_id: this.giftcard.order_detail_id,
      }).then(() => this.$emit('updated', this.giftcard))
    },
  },
}
</script>
