<template>
  <div>
    <div class="box-header">
      <h4 class="box-title">Split giftcard #{{ giftcard.code }}</h4>
    </div>

    <div class="box-body">
      <p>
        Original giftcard is valid for {{ giftcard.product.participants * giftcards.length }} participants, can be split
        up into max {{ giftcards.length }} different giftcards
      </p>
      <p>
        Participants left to assign:
        {{
          giftcard.product.participants * giftcards.length -
            splitted.map(s => s.participants).reduce((a, b) => a + b, 0)
        }}
      </p>
      <table class="table">
        <thead>
          <th>Giftcard</th>
          <th>Participants</th>
        </thead>
        <tbody>
          <tr v-for="(splitGift, i) in splitted" :key="i">
            <td>Giftcard #{{ i + 1 }}</td>
            <td>
              <div class="input-group">
                <span class="input-group-btn">
                  <button
                    class="btn btn-default"
                    @click="splitGift.participants -= giftcard.product.participants"
                    type="button"
                  >
                    -
                  </button>
                </span>
                <input type="text" disabled class="form-control" placeholder="0" v-model="splitGift.participants" />
                <span class="input-group-btn">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="splitGift.participants += giftcard.product.participants"
                  >
                    +
                  </button>
                </span>
              </div>
              <!-- /input-group -->
            </td>
            <td>
              <button v-if="splitted.length > 2 && i > 1" class="btn btn-danger" @click="removeGiftcard(i)">X</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        v-if="availableParticipants.length > 1 && splitted.length < giftcards.length"
        class="btn btn-primary"
        @click="addGiftcard()"
      >
        Add giftcard
      </button>
      <button :disabled="!canSplit" class="btn btn-success float-right" @click="split()">Split giftcards</button>
    </div>
  </div>
</template>

<script>
import { giftcard } from '@/api'
export default {
  props: ['giftcards'],
  data() {
    // var perChunk = this.giftcards.length === 2 ? 1 : 2 // items per chunk

    // var inputArray = this.giftcards;

    // var splittedGiftcards = inputArray.reduce((resultArray, item, index) => {
    //   const chunkIndex = Math.floor(index/perChunk)

    //   if(!resultArray[chunkIndex]) {
    //     resultArray[chunkIndex] = [] // start a new chunk
    //   }

    //   resultArray[chunkIndex].push(item)

    //   return resultArray
    // }, [])

    const splittedGiftcards = [{ participants: 0 }, { participants: 0 }]

    return {
      giftcard: this.giftcards[0],
      splitted: splittedGiftcards,
      totalParticipants: this.giftcards.length * this.giftcards[0].product.participants,
      toSplit: [],
    }
  },
  computed: {
    canSplit() {
      return (
        (this.totalParticipants === this.splitted.map(s => s.participants).reduce((a, b) => a + b, 0) ||
          this.giftcards.length === 2) &&
        this.splitted.filter(s => s.participants > 0).length >= 2
      )
    },
    availableParticipants() {
      const print_all_sum_rec = function(target, current_sum, start, result, output) {
        if (current_sum === target) {
          output.push(result.slice())
        }

        for (let i = start; i < target; i++) {
          const temp_sum = current_sum + i
          if (temp_sum <= target) {
            result.push(i)
            print_all_sum_rec(target, temp_sum, i, result, output)
            result.pop()
          } else {
            return
          }
        }
      }

      const print_all_sum = function(target) {
        const output = []
        const result = []
        print_all_sum_rec(target, 0, 1, result, output)
        return output
      }

      console.log(print_all_sum(10))

      return print_all_sum(this.giftcards.length * this.giftcard.product.participants)
    },
  },
  mounted() {
    //this.updateGiftcards();
  },
  methods: {
    addGiftcard() {
      this.splitted.push({ participants: 0 })
    },
    removeGiftcard(i) {
      this.splitted.splice(i, 1)
    },
    async split() {
      if (this.canSplit) {
        await giftcard.split(
          this.giftcards[0].code,
          this.splitted.map(g => g.participants)
        )
        this.$emit('updated')
        // this.$http
        //   .post(`/api/v1/orders/${this.giftcard.order_id}/${this.giftcard.id}/split`, {
        //     split: this.splitted.map(g => g.participants),
        //   })
        //   .then(res => {
        //     this.$modal.hide('split-modal')
        //     const message = 'Order details successfully splitted'
        //     this.$swal('Reverted', message, 'success')
        //     this.$root.$emit('ordersUpdated')
        //   })
      }
    },
  },
}
</script>
