<template>
  <div>
    <h1 class="page-header">Orders to pack <small>all listings.</small></h1>
    <b-card>
      <b-table
        :fields="fields"
        :items="mapItems(items)"
        show-empty
        stacked="sm"
        bordered
        hover
        @row-clicked="row => showDetails(row)"
      >
        <template v-slot:cell(name)="{ item }">
          {{ `${item.first_name} ${item.last_name}` }}
        </template>

        <template v-slot:cell(paid)="{ item }">
          <b-badge :variant="item.paid ? 'primary' : 'warning'">
            {{ item.paid ? 'Paid' : 'Not Paid' }}
          </b-badge>
        </template>

        <template v-slot:cell(full_sum)="{ item }">
          {{ formatMoney(item.full_sum) }}
        </template>

        <template v-slot:cell(options)="{ item }">
          <b-button variant="default" size="sm" class="mr-2" @click="markPacked([item])">
            Mark as packed
          </b-button>
          <b-button variant="default" size="sm" :href="printLocation([item])" target="_blank">
            Print card/address
          </b-button>
        </template>
        <template v-slot:row-details="{ item }">
          <b-overlay :show="loading === item.id">
            <order-details :order="item" @loaded="loading = 0" />
          </b-overlay>
        </template>
      </b-table>
      <hr v-if="items.length" />

      <b-row v-if="items.length" align-h="end">
        <b-col cols="auto">
          <b-button variant="outline-primary" class="mr-2" :href="printLocation(items)" target="_blank">
            <i class="fa fa-print" />
            Print vouchers
          </b-button>
          <b-button variant="primary" @click="markPacked(items)"> <i class="fa fa-check" /> Mark all as sent </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { orders } from '@/api'
import { formatDate, formatMoney } from '../page-helpers'
import OrderDetails from './OrderDetails.vue'

export default {
  name: 'OrdersToPack',
  components: { OrderDetails },
  data() {
    return {
      loading: 0,
      current: 0,
      items: [],
      ordersIndex: process.env.VUE_APP_ALGOLIA_ORDERS_INDEX,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', sortable: false },
        { key: 'paid', sortable: false },
        { key: 'full_sum', sortable: false },
        { key: 'delivery_method', sortable: false },
        { key: 'created_at', sortable: false, formatter: formatDate },
        { key: 'options' },
      ]
    },
  },
  mounted() {
    this.loadContent()
  },
  methods: {
    formatMoney,
    async loadContent() {
      this.items = (await orders.all({ filter: { to_pack: true } })).data()
    },
    printLocation(items) {
      const url = process.env.VUE_APP_API_URL
      const ids = items.map(item => item.id).join(',')
      return `${url}/orders/print?orders=${ids}`
    },
    showDetails(order) {
      this.loading = order.id
      this.current = order.id === this.current ? 0 : order.id
      order._showDetails = !order._showDetails
    },
    mapItems(items) {
      return items.map(item => ({ ...item, _showDetails: this.current === item.id }))
    },
    async markPacked(items) {
      await orders.markAsPacked(items.map(item => item.id))
      this.$bvToast.toast(`${items.length} order(s) marked as packed.`, {
        title: 'Orders sent.',
        variant: 'primary',
      })
      this.loadContent()
    },
  },
}
</script>
