<template>
  <div id="content" class="app-content">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'OrdersWrapper',
}
</script>
