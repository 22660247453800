<template>
  <div id="content" class="app-content">
    <h1 class="page-header">Pending Reviews <small>all listings.</small></h1>

    <!-- page content -->
    <b-card>
      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(rating)="row">
          <b-form-rating v-model="row.item.rating" readonly inline no-border />
        </template>

        <template v-slot:cell(product_name)="row">
          <router-link :to="{ name: 'products.edit.reviews', params: { id: row.item.product_id } }">{{
            row.item.product_name
          }}</router-link>
        </template>

        <template v-slot:cell(options)="{ item }">
          <b-dropdown :disabled="item.trashed" text="More" variant="light" size="sm" right>
            <b-dropdown-item v-if="!item.approved" @click="approveReview(item)">
              Approve
            </b-dropdown-item>
            <b-dropdown-item v-b-modal="`editReview-${item.id}`">
              Edit
            </b-dropdown-item>

            <b-dropdown-item v-if="item.approved" v-b-modal="`replyReview-${item.id}`">
              Reply
            </b-dropdown-item>

            <b-dropdown-item v-b-modal="`deleteReview-${item.id}`">
              Delete
            </b-dropdown-item>
          </b-dropdown>

          <b-modal
            :id="`editReview-${item.id}`"
            :title="`Edit Review on ${item.product_name}`"
            hide-footer
            v-slot="{ close }"
          >
            <product-review-form :review="item" @refresh="loadContent(close)" @close="close(close)" />
          </b-modal>

          <b-modal
            :id="`replyReview-${item.id}`"
            title="Reply Review"
            hide-footer
            v-slot="{ close }"
            @refresh="loadContent()"
          >
            <product-review-reply-form :review="item" @close="loadContent(close)" />
          </b-modal>

          <b-modal :id="`deleteReview-${item.id}`" title="Delete Review" @ok="deleteReview(item)">
            Are you sure you want to delete review by
            <strong>{{ item.name }}?</strong>
            <pre class="mt-4">{{ item.review }}</pre>
          </b-modal>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-if="showPagination"
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { products } from '@/api'
import ProductReviewForm from './Products/ProductReviewForm.vue'

export default {
  name: 'ReviewsPage',
  components: { ProductReviewForm },
  data() {
    return {
      listing: [],
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  computed: {
    fields() {
      return [
        { key: 'product_name' },
        { key: 'name' },
        { key: 'email' },
        { key: 'rating' },
        { key: 'review' },
        { key: 'approved' },
        { key: 'vote_count' },
        { key: 'options', label: '' },
      ]
    },

    showPagination() {
      return this.meta.total > this.meta.per_page
    },
  },
  beforeMount() {
    this.loadContent()
  },
  methods: {
    close(modal) {
      modal.hide()
    },
    async loadContent() {
      const response = await products.reviews.go(null, this.$route.query.page ?? 1, {
        filter: { not_empty_not_approved: true },
      })

      this.listing = response.data()
      this.meta = response.meta
    },

    async approveReview(item) {
      await products.reviews.approve(item.id)
      this.$bvToast.toast(`Review by "${item.name}" approved.`, {
        title: 'Review approved',
        variant: 'primary',
      })
      this.loadContent()
    },

    async deleteReview(item) {
      await products.reviews.delete(item.id)
      this.$bvToast.toast(`Review by "${item.name}" deleted.`, {
        title: 'Review deleted',
        variant: 'primary',
      })
      this.loadContent()
    },

    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'pending-reviews' } : { name: 'pending-reviews', query: { page } })

      this.loadContent()
    },
  },
}
</script>
