<template>
  <div>
    <b-card header-tag="header">
      <template v-slot:header>
        <h5>Order Details</h5>
      </template>
      <b-table
        ref="order-detail"
        :fields="orderDetailFields"
        :items="filteredOrderDetails"
        select-mode="multi"
        selectable
        @row-selected="toggleSelected"
        :no-select-on-click="true"
      >
        <template v-slot:cell(checkbox)="{ rowSelected, index, item }">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-${item.id}`"
              :checked="rowSelected"
              @change="selectOrderDetailRow(index, item)"
            />
            <label class="custom-control-label" :for="`checkbox-${item.id}`" />
          </div>
        </template>

        <template v-slot:head(checkbox)>
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              :checked="checkedOrderDetails.length === filteredOrderDetails.length"
              @change="selectAllOrderDetailRows()"
              :id="checkId"
            />
            <label class="custom-control-label" :for="checkId" />
          </div>
        </template>

        <template v-slot:cell(price)="{ item }">
          <span>{{ formatMoney(item.price) }}</span>
          <template v-if="item.giftcard">
            &nbsp;
            <b-badge v-if="item.giftcard.open" variant="secondary">OPEN</b-badge>
          </template>
        </template>

        <template v-slot:cell(refundable)="{ item }">
          <b-badge :variant="refundableVariant(item)">
            {{ String(isRefundable(item)).toUpperCase() }}
          </b-badge>
        </template>

        <template v-slot:cell(product)="{ item }">
          <span v-if="item.product.name === 'Fraktkostnad'">
            Gaveeske
          </span>
          <span v-else>
            {{ item.product.name }}
          </span>
        </template>

        <template v-slot:cell(greeting)="{ item }">
          <div v-if="item.giftcard">
            <div v-if="item.giftcard.custom_greeting">
              <details>
                <summary>Digital greeting</summary>
                {{ item.giftcard.custom_greeting }}
              </details>
            </div>
            <div v-if="item.giftcard.custom_pdf_greeting">
              <details>
                <summary>PDF greeting</summary>
                {{ item.giftcard.custom_pdf_greeting }}
              </details>
            </div>
          </div>
        </template>

        <template v-slot:cell(code)="{ item }">
          <b-row align-v="center">
            <b-col
              cols="auto"
              :class="{
                'text-danger':
                  item.refunded_at || (item.giftcard && (item.giftcard.exchanged_at || isExpired(item)) && !item.giftcard.redeemed_at),
                'text-primary': item.giftcard && item.giftcard.redeemed_at,
              }"
            >
              {{ item.code }}
            </b-col>

            <b-badge v-if="voucherStatus(item)">
              {{ voucherStatusText(item) }}
            </b-badge>

            <b-badge v-if="item.booking_email || item.old_used_at">
              Booked by: {{ item.booking_email || item.order_email }}
            </b-badge>

            <template v-if="isPaid(item)">
              <b-col cols="auto">
                <b-button variant="primary" v-b-modal="`order-info-${item.id}`" size="sm">
                  <i class="fa fa-info" />
                </b-button>

                <b-modal title="Order Info" :id="`order-info-${item.id}`" ok-only hide-footer>
                  <order-detail-info
                    :product_id="item.product.product_id"
                    :old_product_info_id="item.old_product_info_id"
                    :filteredOrderDetails="filteredOrderDetails"
                    :giftcard="item.giftcard"
                    @triggerLoadOrderDetails="loadOrderDetails"
                    @ok="$bvModal.hide(`order-info-${item.id}`)"
                  />
                </b-modal>
              </b-col>

              <b-col cols="auto">
                <b-button variant="primary" size="sm" title="copy voucher code" @click="copyText(item.code)">
                  <i class="fa fa-copy" />
                </b-button>
              </b-col>

              <b-col cols="auto">
                <b-button
                  :href="`${rootUrl}/giftcard/${item.giftcard.id}/download`"
                  target="_BLANK"
                  variant="primary"
                  size="sm"
                  title="download voucher"
                >
                  <i class="fa fa-download" />
                </b-button>
                <b-button
                  v-if="item.giftcard.exchanged_at"
                  :href="`${rootUrl}/giftcard/${item.giftcard.exchanged_to}/download`"
                  target="_BLANK"
                  variant="secondary"
                  size="sm"
                  class="ml-2"
                  title="download exchanged voucher"
                >
                  <i class="fa fa-download" />
                </b-button>
              </b-col>
            </template>
            <br />

            <template v-if="item.giftcard?.show_resend_email_buttons">
              <b-col cols="auto">
                <b-button
                  variant="primary"
                  size="sm"
                  title="Resend activation email to user"
                  @click="resendActivationEmail(item.code, 'user')"
                  :disabled="resendToUserLoading"
                >
                  <i class="fas fa-paper-plane mr-1"></i> Resend to user
                  <b-spinner v-show="resendToUserLoading" class="ml-2" small label="Loading" />
                </b-button>
              </b-col>

              <b-col cols="auto">
                <b-button
                  variant="primary"
                  size="sm"
                  title="Send activation email to the currently logged in admin"
                  @click="resendActivationEmail(item.code, 'admin')"
                  :disabled="resendToAdminLoading"
                >
                  <i class="fas fa-paper-plane mr-1"></i> Resend to admin
                  <b-spinner v-show="resendToAdminLoading" class="ml-2" small label="Loading" />
                </b-button>
              </b-col>
            </template>
          </b-row>
        </template>

        <template v-slot:cell(valid_to)="{ item }">
          <b-row align-v="center">
            <b-col cols="auto">
              {{ formatValidToDate(item) }}
            </b-col>

            <b-col v-if="isPaid(item)" cols="auto">
              <b-button
                v-b-modal="`extend-valid-date-${item.id}`"
                variant="primary"
                size="sm"
                title="extend valid date"
                v-if="canExtendValidDate(item)"
              >
                <i class="fa fa-edit" />
              </b-button>

              <b-modal
                title="Extend Valid Date"
                :id="`extend-valid-date-${item.id}`"
                v-slot="{ ok, cancel }"
                hide-footer
              >
                <order-detail-date-form :giftcard="item.giftcard" @cancel="cancel" @updated="giftcardUpdated(ok)" />
              </b-modal>
            </b-col>
          </b-row>
        </template>
        <template v-slot:custom-foot>
          <tr>
            <td></td>
            <td><strong>Sum</strong></td>
            <td>
              <strong>{{ formatMoney(orderSum) }}</strong>
            </td>
          </tr>
          <tr v-if="order.giftcard_id">
            <td></td>
            <td><strong>Sum giftcard</strong></td>
            <td>
              <strong>-{{ formatMoney(order.giftcard_sum) }}</strong>
            </td>
          </tr>
          <tr v-if="order.delivery_count > 0 && parseInt(order.delivery_cost.amount) > 0">
            <td></td>
            <td><strong>Giftbox ({{ order.delivery_count }})</strong></td>
            <td>
              <strong>{{ formatMoney(order.delivery_cost) }}</strong>
            </td>
          </tr>
          <tr v-if="parseInt(paymentCost.amount) > 0">
            <td></td>
            <td><strong>{{ order.payment_method }}</strong></td>
            <td>
              <strong>{{ formatMoney(paymentCost) }}</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td><strong>Total</strong></td>
            <td>
              <strong
                >{{ formatMoney({ amount: order.full_sum.amount - order.giftcard_sum.amount, currency: 'NOK' }) }}
              </strong>
            </td>
          </tr>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <b-dropdown v-if="checkedOrderDetails.length && showActionsDropdown" id="dropdown-1" text="Choose action" class="m-md-2">
            <b-dropdown-item v-if="canRefund" v-b-modal="'refund-selected-giftcards'">Refund</b-dropdown-item>
            <b-dropdown-item v-if="canExchange" v-b-modal="'exchange-giftcards'">Exchange</b-dropdown-item>
            <b-dropdown-item v-if="canExchangeAndCombine" v-b-modal="'exchange-combine-giftcards'">Exchange & Combine</b-dropdown-item>
            <b-dropdown-item v-if="canSplit" @click.prevent="split()">Split giftcard</b-dropdown-item>
            <b-dropdown-item v-if="canRevertExchanged" v-b-modal="'exchange-back-giftcards'">Revert Exchanged</b-dropdown-item>
            <b-dropdown-item v-if="canMarkNotUsed" v-b-modal="'not-used-giftcards'">Set as not used</b-dropdown-item>
            <b-dropdown-item v-if="canCreditAndMarkNotUsed" v-b-modal="'credit-giftcard'">Credit & Set as not used</b-dropdown-item>
            <b-dropdown-item v-if="canRedeem" v-b-modal="'redeem-giftcards'">Redeem selected</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-4">
      <b-col>
        <b-card header-tag="header">
          <template v-slot:header>
            <h5>
              Customer
              <b-button variant="default" size="sm" @click="editCustomer = !editCustomer" class="float-right"
                >Edit</b-button
              >
            </h5>
          </template>
          <div v-if="editCustomer">
            <form @submit.prevent="updateCustomer">
              <b-row>
                <b-col>
                  <b-form-group label="First Name">
                    <b-form-input v-model="customerData.first_name" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Last Name">
                    <b-form-input v-model="customerData.last_name" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Phone">
                    <b-form-input v-model="customerData.phone" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Email">
                    <b-form-input v-model="customerData.email" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Address">
                    <b-form-input v-model="customerData.address" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Zip">
                    <b-form-input v-model="customerData.zip" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="City">
                    <b-form-input v-model="customerData.city" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="auto">
                  <b-button variant="primary" style="margin-bottom: 1rem" type="submit">
                    Update
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </div>
          <b-table v-if="!editCustomer" borderless small :items="customerDetailFields">
            <template v-slot:cell(key)="row">
              <strong>{{ row.item.key }}:</strong>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col>
        <b-card header-tag="header">
          <template v-slot:header>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Payment Details</h5>

              <b-button v-if="!allRefunded && order.paid" variant="danger" size="sm" v-b-modal="'refund-giftcards'">
                Refund order
              </b-button>

              <b-button v-if="!order.paid" variant="primary" size="sm" @click.prevent="markPaid">
                Mark as Paid
                <div v-if="isLoading" class="spinner-border spinner-border-sm text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </b-button>

              <b-button v-if="order.giftcard_id && !order.paid" variant="warning" size="sm" v-b-modal="'revert-to-giftcard'">
                Revert to giftcard
              </b-button>

              <b-button v-if="canAddGiftcard" variant="primary" size="sm" v-b-modal="'add-giftcard'"
                >Add giftcard</b-button
              >
            </div>
          </template>

          <b-table borderless small :items="paymentDetailFields">
            <template v-slot:cell(key)="row">
              <strong>{{ row.item.key }}:</strong>
            </template>
            <template v-slot:cell(value)="row">
              <span v-if="row.item.key === 'Hash'">
                <a
                  :href="`${frontendUrl}/checkout/${order.id}/${order.hash}/${order.paid ? 'receipt' : 'payment'}`"
                  target="_blank"
                  >{{ row.item.value }}</a
                >
              </span>
              <span v-else>
                {{ row.item.value }}
              </span>
            </template>
          </b-table>
          <div v-if="order.paid">
            <b-button
              :href="downloadUrl"
              target="_blank"
              variant="primary"
              class="mr-2"
              size="sm"
            >
              Download Receipt
            </b-button>
            <b-button variant="primary" class="mr-2" size="sm" @click="resendReciept">Resend Receipt</b-button>
            <b-button variant="primary" class="mr-2" size="sm" @click="resendVouchers" :disabled="resendVouchersIsLoading">
              Resend Vouchers
              <b-spinner v-show="resendVouchersIsLoading" class="ml-2" small label="Loading" />
            </b-button>
            <b-button variant="primary" size="sm" @click="regenerateVouchers" :disabled="regenerateVouchersIsLoading">
              Regenerate Vouchers
              <b-spinner v-show="regenerateVouchersIsLoading" class="ml-2" small label="Loading" />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-card header-tag="header">
          <template v-slot:header>
            <h5 class="mb-0">Comments</h5>
          </template>
          <order-comments @updated="giftcardUpdated(() => false)" :data="comments" :order_id="order.id" />
        </b-card>
      </b-col>
    </b-row>

    <add-giftcard-modal :order="order" @triggerUpdate="$emit('triggerUpdate')" />

    <b-modal title="Confirm revert to giftcard" id="revert-to-giftcard" @ok="revertToGiftcard">
      Are you sure that you want to revert to giftcard?
    </b-modal>

    <redeem-giftcard-modal
      :orderDetails="checkedOrderDetails"
      @triggerUpdate="$emit('triggerUpdate')"
      @triggerLoadOrderDetails="loadOrderDetails"
    />

    <b-modal title="Confirm Refund" :id="`refund-selected-giftcards`" @ok="refundItems">
      Are you sure that you want to refund the selected giftcards?
    </b-modal>

    <b-modal title="Confirm Refund" :id="`refund-giftcards`" @ok="refund">
      Are you sure that you want to refund the order?
    </b-modal>

    <b-modal title="Confirm Set As Not Used" :id="`not-used-giftcards`" @ok="markNotUsed">
      Set as not used (unredeem product voucher that have not been invoiced)<br>
      This is done in the order, from a voucher that is redeemed.
    </b-modal>

    <b-modal title="Confirm Exchange" :id="`exchange-giftcards`" @ok="exchangeGiftcards">
      Are you sure that you want to exchange the selected giftcards?
      <form>
        <div class="form-group">
          <label>Enter email of person to receive the exchanged giftcard PDF's</label>
          <input type="email" v-model="exchangeEmail" class="form-control" />
        </div>
      </form>
    </b-modal>

    <b-modal title="Confirm Revert Exchanged" :id="`exchange-back-giftcards`" @ok="revertExchanged">
      Are you sure that you want to revert the selected giftcard?
    </b-modal>

    <b-modal title="Confirm Part Crediting" :id="`credit-giftcard`" @ok="markPartialCredit">
      Are you sure that you want to part credit invoice?
      <br><br>
      <form>
        <div class="form-group">
            <b-form-group>
                <b-textarea v-model="comment" placeholder="Comment" required />
            </b-form-group>
        </div>
      </form>
    </b-modal>

    <b-modal title="Confirm Exchange" :id="`exchange-combine-giftcards`" @ok="exchangeCombineGiftcards">
      Are you sure that you want to exchange and combine the selected giftcards?
      <form>
        <div class="form-group">
          <label>Enter email of person to recieve the exchanged giftcard PDF's</label>
          <input type="email" v-model="exchangeEmail" class="form-control" />
        </div>
      </form>
    </b-modal>

    <b-modal title="Split giftcards" :id="`split`" v-slot="{ ok, cancel }" hide-footer>
      <split-giftcard :giftcards="giftcardsToSplit" @cancel="cancel" @updated="giftcardUpdated(ok)" />
    </b-modal>
  </div>
</template>

<script>
import {giftcard, orders, booking, invoices} from '@/api'
import { adjustExpirationDate, formatDate, formatMoney } from '../page-helpers'
import OrderDetailInfo from './OrderDetailInfo.vue'
import OrderDetailDateForm from './OrderDetailDateForm.vue'
import SplitGiftcard from './SplitGiftcard.vue'
import OrderComments from './OrderComments.vue'
import AddGiftcardModal from './modals/AddGiftcardModal.vue'
import RedeemGiftcardModal from './modals/RedeemGiftcardModal.vue'

export default {
  name: 'OrderDetails',
  components: {
    OrderDetailInfo,
    OrderDetailDateForm,
    SplitGiftcard,
    OrderComments,
    AddGiftcardModal,
    RedeemGiftcardModal,
  },
  props: { order: { type: Object } },
  data() {
    return {
      downloadUrl: `${process.env.VUE_APP_API_URL}/order/${this.order.hash}/downloadReciept`,
      orderDetails: [],
      editCustomer: false,
      exchangeEmail: '',
      checkedOrderDetails: [],
      giftcardsToSplit: [],
      comments: this.order.comments,
      orderSum: this.order.sum,
      paymentCost: this.order.payment_cost,
      rootUrl: process.env.VUE_APP_API_URL,
      frontendUrl: process.env.VUE_APP_APP_URL,
      comment: null,
      customerData: {
        first_name: this.order.first_name,
        last_name: this.order.last_name,
        phone: this.order.phone,
        email: this.order.email,
        address: this.order.address,
        zip: this.order.zip,
        city: this.order.city,
      },
      isLoading: false,
      resendVouchersIsLoading: false,
      resendToUserLoading: false,
      resendToAdminLoading: false,
      regenerateVouchersIsLoading: false,
    }
  },
  computed: {
    showActionsDropdown() {
      return this.canRefund ||
          this.canExchange ||
          this.canExchangeAndCombine ||
          this.canSplit ||
          this.canRevertExchanged ||
          this.canMarkNotUsed ||
          this.canCreditAndMarkNotUsed ||
          this.canRedeem
    },
    orderDetailFields() {
      return [
        { key: 'checkbox', label: '' },
        { key: 'product', formatter: value => value.name },
        { key: 'price', formatter: value => `${value.amount} kr` },
        { key: 'code', label: 'Voucher Code' },
        {
          key: 'refundable',
          formatter: (value, key, item) => this.isRefundable(item),
        },
        { key: 'valid_to' },
        { key: 'greeting', formatter: (value, key, item) => item?.giftcard?.custom_greeting },
      ]
    },
    customerDetailFields() {
      const {
        first_name,
        last_name,
        phone,
        email,
        address,
        city,
        zip,
        company_name,
        company_organization_number,
      } = this.order

      return [
        { key: 'Company Name', value: company_name },
        { key: 'Company Organization Number', value: company_organization_number },
        { key: 'Name', value: `${first_name} ${last_name}` },
        { key: 'Phone', value: phone },
        { key: 'E-mail', value: email },
        { key: 'Address', value: address },
        { key: 'Postnumber/place', value: `${zip} ${city}` },
      ]
    },
    paymentDetailFields() {
      const { payment_method, hash, delivery_method, paid, payment_received_at, giftcard_id, giftcard_sum } = this.order

      const optionalFields = []
      if (giftcard_id) {
        optionalFields.push({
          key: 'Giftcard ID',
          value: giftcard_id,
        })
        optionalFields.push({
          key: 'Giftcard Sum',
          value: formatMoney(giftcard_sum),
        })
      }

      return [
        { key: 'Payment Method', value: payment_method },
        { key: 'Hash', value: hash },
        { key: 'Delivery Method', value: delivery_method },
        { key: 'Paid At', value: formatDate(payment_received_at) },
        { key: 'Response', value: paid ? 'Paid' : 'Not Paid' },
        ...optionalFields,
      ]
    },
    checkId() {
      return Math.random().toString(4)
    },
    filteredOrderDetails() {
      return this.orderDetails.filter(detail => Number(detail.price.amount) !== 0 && detail.type !== 'order_addons')
    },
    canRefund() {
      return this.checkedOrderDetails.every(od => this.isRefundable(od))
    },
    allRefunded() {
      return this.orderDetails.every(od => od.refunded_at)
    },
    canMarkNotUsed() {
      return this.checkedOrderDetails.every(
        od =>
          od.giftcard && od.giftcard.redeemed_at !== null &&
            (od.giftcard?.invoice_status === 'redeemed' || od.giftcard?.invoice_status === 'redeemed_submitted') &&
            od.refunded_at === null &&
            od.giftcard.invoice_status !== 'redeemed_invoiced'
      )
    },
    canCreditAndMarkNotUsed() {
      return this.checkedOrderDetails.every(
        od =>
          od.giftcard &&
            od.giftcard.redeemed_at !== null &&
            od.refunded_at === null &&
            od.giftcard.invoice_status === 'redeemed_invoiced'
      )
    },
    canRedeem() {
      if (this.checkedOrderDetails.every(od => Number.isInteger(od))) {
        // Ugly hack but something went wrong, and we have only ID's instead of full rows
        const checkedOrderDetails = this.filteredOrderDetails.filter(od => this.checkedOrderDetails.includes(od.id))

        return checkedOrderDetails.every(
            od =>
                od.giftcard &&
                od.giftcard.redeemed_at === null &&
                od.giftcard.exchanged_at === null &&
                od.refunded_at === null &&
                !this.isExpired(od)
        )
      }

      return this.checkedOrderDetails.every(
        od =>
          od.giftcard &&
          od.giftcard.redeemed_at === null &&
          od.giftcard.exchanged_at === null &&
          od.refunded_at === null &&
          !this.isExpired(od)
      )
    },
    canExchange() {
      return this.checkedOrderDetails.every(
          od => od.giftcard &&
            od.giftcard.exchanged_at === null &&
            od.giftcard.redeemed_at === null &&
            od.giftcard.refunded_at === null
      )
    },
    canExchangeAndCombine() {
      return (
        this.checkedOrderDetails.every(
          od => od.giftcard && od.giftcard.exchanged_at === null && od.giftcard.redeemed_at === null
        ) && this.checkedOrderDetails.length > 1
      )
    },
    canRevertExchanged() {
      return this.checkedOrderDetails.every(
          od => od.giftcard &&
          od.giftcard.exchanged_at &&
          od.giftcard.refunded_at === null &&
          od.giftcard.sum.amount === od.giftcard.full_sum.amount
      )
    },
    canSplit() {
      const checkedOrderDetails = this.fixCheckedOrderDetailsIfNeeded()
      const isSplittable =
        this.orderDetails.filter(
            od => od.code === checkedOrderDetails[0].code && od.code !== null && od.giftcard.redeemed_at === null && od.refunded_at === null
        ).length > 1
          && checkedOrderDetails[0].giftcard.redeemed_at === null
          && checkedOrderDetails[0].refunded_at === null
          && this.order.paid

      return checkedOrderDetails.length === 1 && isSplittable
    },
    canAddGiftcard() {
      const numberOfOpenGiftcardsInOrder = this.filteredOrderDetails.filter(
          orderDetails => orderDetails?.giftcard.type !== 'product' && orderDetails?.giftcard.type !== 'product_variations'
      ).length

      return !this.order.giftcard_id && numberOfOpenGiftcardsInOrder === 0
    },
  },
  beforeMount() {
    this.loadOrderDetails()
  },
  methods: {
    formatDate,
    formatMoney,
    fixCheckedOrderDetailsIfNeeded() {
      if (this.checkedOrderDetails.every(od => Number.isInteger(od))) {
        // Ugly hack but something went wrong, and we have only ID's instead of full rows
        return [...this.filteredOrderDetails.filter(od => this.checkedOrderDetails.includes(od.id))]
      }

      return [...this.checkedOrderDetails]
    },
    async loadOrderDetails() {
      const response = await orders.details.all(this.order.id)
      this.orderDetails = response.data().sort(this.orderDetailsSort);

      const orderResponse = await orders.find(this.order.id)
      this.comments = orderResponse.data?.comments
      this.orderSum = orderResponse.data?.sum
      this.paymentCost = orderResponse.data?.payment_cost

      this.$emit('loaded')
    },
    async resendReciept() {
      try {
        await orders.resendReciept(this.order.id)
        this.$bvToast.toast(`Receipt email resent`, {
          title: 'Receipt email resent',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to send receipt by email',
          variant: 'danger',
        })
      }
    },
    async resendVouchers() {
      this.resendVouchersIsLoading = true;

      try {
        await orders.resendVouchers(this.order.id)
        this.$bvToast.toast(`The voucher email is regenerated and sent`, {
          title: 'Voucher email sent',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to send voucher by email',
          variant: 'danger',
        })
      }

      this.resendVouchersIsLoading = false;
    },
    async regenerateVouchers() {
      this.regenerateVouchersIsLoading = true;

      try {
        await orders.regenerateVouchers(this.order.id)
        this.$bvToast.toast('Vouchers regenerated', {
          title: 'Vouchers regenerated',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to regenerate vouchers',
          variant: 'danger',
        })
      }

      this.regenerateVouchersIsLoading = false;
    },
    async updateCustomer() {
      try {
        const { data } = await orders.update(
          this.order.id,
          this.customerData.first_name,
          this.customerData.last_name,
          this.customerData.phone,
          this.customerData.email,
          this.customerData.address,
          this.customerData.zip,
          this.customerData.city
        )
        this.updateCustomerData(data)
        this.$bvToast.toast(`Customer data updated`, {
          title: 'Customer updated',
          variant: 'primary',
        })
        this.$emit('triggerUpdate')
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to update customer',
          variant: 'danger',
        })
      } finally {
        this.editCustomer = false
      }
    },
    updateCustomerData(orderData) {
      this.order.first_name = this.customerData.first_name = orderData.first_name
      this.order.last_name = this.customerData.last_name = orderData.last_name
      this.order.phone = this.customerData.phone = orderData.phone
      this.order.email = this.customerData.email = orderData.email
      this.order.address = this.customerData.address = orderData.address
      this.order.zip = this.customerData.zip = orderData.zip
      this.order.city = this.customerData.city = orderData.city
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(() =>
        this.$bvToast.toast(`Voucher code has been copied to clipboard.`, {
          title: 'Voucher code copied',
          variant: 'primary',
        })
      )
    },
    async resendActivationEmail(code, role) {
      this[role === 'admin' ? 'resendToAdminLoading' : 'resendToUserLoading'] = true;

      try {
        await booking.resendVerificationEmail(code, role)
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong...',
          variant: 'danger',
        })
      }

      this[role === 'admin' ? 'resendToAdminLoading' : 'resendToUserLoading'] = false;
    },
    async markPaid() {
      try {
        this.isLoading = true
        await orders.markAsPaid(this.order.id)
        this.order.paid = true
        this.$emit('triggerUpdate')
        this.$bvToast.toast(`Order mark as paid.`, {
          title: 'Order paid',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to mark as paid',
          variant: 'danger',
        })
      } finally {
        this.isLoading = false
      }
    },
    split() {
      this.giftcardsToSplit = this.orderDetails.filter(od => od.code === this.checkedOrderDetails[0].code && od.giftcard.redeemed_at === null)
      this.$bvModal.show('split')
    },
    toggleSelected(rows) {
      this.checkedOrderDetails = rows
    },
    async giftcardUpdated(done) {
      try {
        done()
        await this.loadOrderDetails()
        this.$bvToast.toast(`Giftcard updated.`, {
          title: 'Giftcard updated',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to update giftcard',
          variant: 'danger',
        })
      }
    },
    async exchangeGiftcards() {
      try {
        await giftcard.exchange(
          this.checkedOrderDetails.map(od => od.code),
          this.exchangeEmail
        )
        await this.loadOrderDetails()
        this.$bvModal.hide(`exchange-giftcards`)
        this.$bvToast.toast(`Giftcards exchanged`, {
          title: 'Giftcard exchanged',
          variant: 'primary',
        })
        this.$emit('triggerUpdate')
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to exchange giftcard',
          variant: 'danger',
        })
      }
    },
    async revertExchanged() {
      try {
        await giftcard.revertExchangedToOriginal(this.checkedOrderDetails.map(od => od.code)[0], this.order.id)
        await this.loadOrderDetails()
        this.$bvModal.hide(`exchange-back-giftcards`)
        this.$bvToast.toast(`Giftcard reverted`, {
          title: 'Exchanged giftcard reverted',
          variant: 'primary',
        })
        this.$emit('triggerUpdate')
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to revert giftcard',
          variant: 'danger',
        })
      }
    },
    async exchangeCombineGiftcards() {
      try {
        await giftcard.exchange(
          this.checkedOrderDetails.map(od => od.code),
          this.exchangeEmail,
          true
        )
        await this.loadOrderDetails()
        this.$bvModal.hide(`exchange-giftcards`)
        this.$bvToast.toast(`Giftcards exchanged & combined`, {
          title: 'Giftcard exchanged',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Giftcard exchange error',
          variant: 'danger',
        })
      }
    },
    selectAllOrderDetailRows() {
      const table = this.$refs['order-detail']
      if (this.filteredOrderDetails.length === this.checkedOrderDetails.length) {
        this.checkedOrderDetails = []
        table.clearSelected()
      } else {
        this.checkedOrderDetails = this.filteredOrderDetails.map(item => item.id)
        table.selectAllRows()
      }
    },
    selectOrderDetailRow(index, item) {
      const table = this.$refs['order-detail']

      if (table.isRowSelected(index)) {
        const itemIndex = this.checkedOrderDetails.findIndex(id => id === item.id)
        this.checkedOrderDetails.splice(itemIndex, 1)
        table.unselectRow(index)
      } else {
        this.checkedOrderDetails = [...new Set([...this.checkedOrderDetails, item.id])]
        table.selectRow(index)
      }
    },
    async refund() {
      try {
        await orders.refund(this.order.id)
        await this.loadOrderDetails()
        this.$bvToast.toast(`Giftcard refunded`, {
            title: 'Giftcard refunded',
            variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to make a refund',
          variant: 'danger',
        })
      }
    },
    async markNotUsed() {
      try {
        await giftcard.setNotUsed(this.checkedOrderDetails.map(od => od.id))
        await this.loadOrderDetails()
        this.$bvToast.toast(`Giftcard marked as not used`, {
            title: 'Giftcard marked as not used',
            variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong...',
          variant: 'danger',
        })
      }
    },
    async markPartialCredit() {
      try {
          await invoices.partialCredit({
              'invoice_details_id': this.checkedOrderDetails.map(orderDetail => orderDetail.giftcard.invoice_detail_id),
              'comment': this.comment
          })
        await this.loadOrderDetails()
        this.$bvToast.toast(`Giftcard invoice detail part credited`, {
          title: 'Giftcard invoice detail part credited',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Something went wrong...',
          variant: 'danger',
        })
      }
    },
    async refundItems() {
      try {
        const codes = this.checkedOrderDetails.map(od => od.code)
        await orders.refund(this.order.id, { codes })
        await this.loadOrderDetails()
        this.$emit('triggerUpdate')
          this.$bvToast.toast(`Verdibevis med nummer ${codes} er nå refundert.`, {
              title: 'Giftcard refunded',
              variant: 'primary',
          })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to make a refunds',
          variant: 'danger',
        })
      }
    },
    async revertToGiftcard() {
      try {
        const response = await orders.revertToGiftcard(this.order.id)
        this.order.giftcard_id = response.data.giftcard_id
        this.order.giftcard_sum = response.data.giftcard_sum
        this.$emit('triggerUpdate')
          this.$bvToast.toast('Order refunded to giftcard', {
              title: 'Refunded to giftcard',
              variant: 'primary',
          })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Failed to refund to giftcard',
          variant: 'danger',
        })
      }
    },
    isRefundable(item) {
      if (!item.giftcard) {
          return !item.refunded_at
      }

      if (item.refunded_at) {
          return false;
      }

      if (item.giftcard.redeemed_at) {
          return false;
      }

      if (!this.order.paid) {
          return false
      }

      if (item.giftcard.original_sum.amount !== item.giftcard.sum.amount) {
          return false;
      }

      return item.giftcard?.refundable
    },
    refundableVariant(item) {
      return this.isRefundable(item) ? 'primary' : 'secondary'
    },
    formatValidToDate(item) {
      return item.giftcard ? formatDate(item?.giftcard?.valid_to) : ''
    },
    isPaid(item) {
      return item.type !== 'order_addons'
    },
    isExpired(orderDetail) {
      return Date.now() - Date.parse(orderDetail.giftcard.valid_to) > 0 && this.order.paid
    },
    voucherStatus(item) {
      const giftcard = item.giftcard
      if (giftcard === null) return 'secondary'
      if (giftcard.refunded_at) return `danger`
      if (giftcard.redeemed_at) return `primary`
      if (giftcard.exchanged_at) return `primary`
      if (Date.now() - Date.parse(giftcard.valid_to) > 0 && this.order.paid) return `secondary`
    },
    voucherStatusText(item) {
      const giftcard = item.giftcard
      if (giftcard === null) {
        if (item.refunded_at) return ` Refunded at ${formatDate(item.refunded_at)}`
        return ''
      }
      if (giftcard.refunded_at) return ` Refunded at ${formatDate(giftcard.refunded_at)}`
      if (giftcard.redeemed_at) return ` Redeemed at ${formatDate(giftcard.redeemed_at)}`
      if (giftcard.exchanged_at) return ` Exchanged at ${formatDate(giftcard.exchanged_at)}`

      if (Date.now() - Date.parse(giftcard.valid_to) > 0 && this.order.paid) return ` Expired at ${formatDate(adjustExpirationDate(giftcard.valid_to))}`
    },
    orderDetailsSort(orderDetailA, orderDetailB) {
      if (orderDetailA.product.name === 'Betalingsavgift' && orderDetailB.product.name !== 'Betalingsavgift') {
        return 1;
      } else if (orderDetailB.product.name === 'Betalingsavgift' && orderDetailA.product.name !== 'Betalingsavgift') {
        return -1;
      } else if (orderDetailA.product.name === 'Fraktkostnad' && orderDetailB.product.name !== 'Fraktkostnad') {
        return 1;
      } else if (orderDetailB.product.name === 'Fraktkostnad' && orderDetailA.product.name !== 'Fraktkostnad') {
        return -1;
      }

      return orderDetailA.id - orderDetailB.id;
    },
    canExtendValidDate(item) {
      return item.giftcard &&
          item.giftcard.redeemed_at === null &&
          item.giftcard.exchanged_at === null &&
          item.refunded_at === null &&
          this.order.paid
    },
  },
}
</script>
